<script>
//import div from "div-vue";
import firebase from "firebase/app";
import { formatDistance } from "date-fns";
//import { fr } from 'date-fns/locale'

const firebaseConfig = {
  apiKey: "AIzaSyAVoPK2FpE6cOVshGuiYg58Glhz8XLyG4I",
  authDomain: "rmobility2020-46bea.firebaseapp.com",
  projectId: "rmobility2020-46bea",
  databaseURL: "https://rmobility2020-46bea.firebaseio.com",
};

const firebaseApp = firebase.initializeApp(firebaseConfig);

// Required for side-effects
require("firebase/firestore");

export default {
  //components: { div },
  data() {
    return {
      newnotif: [],
      notif: [],
      db: firebaseApp.firestore(),
    };
  },
  mounted() {
    // Initialize Cloud Firestore through Firebase
    /*var db = firebaseApp.firestore();
    this.getAll(db);
    console.log('this.notif:', this.notif)*/
  },
  created() {
    // Initialize Cloud Firestore through Firebase
    var db = firebaseApp.firestore();

    db.collection("notifdash").onSnapshot((res) => {
      const changes = res.docChanges();
      console.log("changes:", changes);
      this.getAll();
    });

    this.getAll();
  },
  methods: {
    getAll() {
      this.notif = [];
      this.db
        .collection("notifdash")
        .orderBy("dateNotif", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            if (data.seen == false) {
              this.notif.push({
                id: doc.id,
                idUser: data.idUser,
                textNotif: data.textNotif,
                seen: data.seen,
                typeNotif: data.typeNotif,
                dateNotif: formatDistance(
                  new Date(data.dateNotif.toDate()),
                  new Date(),
                  { addSuffix: true },
                  { locale: "fr_FR" }
                ),
              });
            }
          });
        });

      console.log("notif:", this.notif);
    },
    getHistory() {
      this.notif = [];
      this.db
        .collection("notifdash")
        .orderBy("dateNotif", "desc")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            var data = doc.data();
            if (data.seen == true) {
              this.notif.push({
                idUser: data.idUser,
                textNotif: data.textNotif,
                seen: data.seen,
                typeNotif: data.typeNotif,
                dateNotif: formatDistance(
                  new Date(data.dateNotif.toDate()),
                  new Date(),
                  { addSuffix: true },
                  { locale: "fr_FR" }
                ),
              });
            }
          });
        });
    },
    async traiter(id) {
      var aTraiter = this.db.collection("notifdash").doc(id);
      return aTraiter
        .update({
          seen: true,
        })
        .then(() => {
          console.log("Document successfully updated!");
          //this.getAll();
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });
      //console.log("id:", id);
    },
  },
};
</script>

<template>
  <div class="card historique">
    <div class="card-body" style="max-height: 200px;">
      <div class="float-right d-none d-md-inline-block">
        <div class="btn-group mb-2">
          <button type="button" class="btn btn-sm btn-light" @click="getAll">
            Nouvelle
          </button>
          <button
            type="button"
            class="btn btn-sm btn-light"
            @click="getHistory"
          >
            Historique
          </button>
        </div>
      </div>
      <h4 class="card-title mb-4">Notifications</h4>
       </div>
  </div>
</template>
<style>
.historique {
  height: 41vw;
}
</style>
