<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

//import Stat from "./widget";
//import RevenueAnalytics from "./revenue";
import SalesAnalytics from "./sales-analytics";
/*import EarningReport from "./earning";
import Sources from "./sources";
import RecentActivity from "./recent-activity";
import RevenueLocation from "./revenue-location";
import Chat from "./chat";
import Transaction from './transaction'; */
import { apiRequest } from "@/helpers/api-call";

/**
 * Dashboard component
 */
export default {
  components: {
    Layout,
    PageHeader,

    //RevenueAnalytics,
    SalesAnalytics,
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Dashboard",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],

      info: {
        totalUser: 0,
        trajetsAnnule: 0,
        trajetsEnCours: 0,
        trajetsAVenir: 0,
        trajetsEffectue: 0,
        totalCOEvite: 0,
        distance: 0,
      },

      dashboardInfo : {
        totalLocation: 0,
        totalTransaction : 0,
        totalVehiculeLocation : 26,
        coutTransaction : 0,
        nbr_autopartage : 1,
        cout_tansaction : 0
}
      


    };
  },
  async mounted() {
    //Chargement des info
    const data = await apiRequest("GET", "admin/rmo/dash-info", undefined, false);
    //console.log("data.data:", data.data);
    if (data && data.data) {
      console.log("data.data:", data.data);
      this.dashboardInfo.totalLocation= data.data.totalLocation,
      this.dashboardInfo.totalTransaction =data.data.totalTransaction ,
      this.dashboardInfo.totalVehiculeLocation = data.data.totalVehiculeLocation,
      this.dashboardInfo.coutTransaction = data.data.coutTransaction ,
      this.dashboardInfo.nbr_autopartage= data.data.nbr_autopartage,
       this.dashboardInfo.cout_tansaction = data.data.cout_tansaction

    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-xl-8">
        <div class="row">
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-secondary"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Nombre total de location
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.totalLocation}}</h1>
              </div>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-warning"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Nombre total de transactions
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.totalTransaction}}</h1>
              </div>
            </b-card>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-success"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Nombre total de véhicules en location
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.totalVehiculeLocation}}</h1>
              </div>
            </b-card>
          </div>
          <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-success"
            >
              <template v-slot:header>
                <h5 class="my-0">
                  Coût des transactions
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.coutTransaction}}</h1>
              </div>
            </b-card>
          </div>
           <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-success"
            >
              <template v-slot:header>
                <h5 class="my-0">
                Nombre d'autopartage effectués
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.nbr_autopartage}}</h1>
              </div>
            </b-card>
          </div>
           <div class="col-md-6">
            <b-card
              header-class="bg-transparent border-primary"
              class="border border-success"
            >
              <template v-slot:header>
                <h5 class="my-0">
               Coût des transactions d'autopartages
                </h5>
              </template>
              <div class=" float-right">
                <h1 class="text-primary">{{dashboardInfo.cout_tansaction}}</h1>
              </div>
            </b-card>
          </div>
        </div>
        <!-- <RevenueAnalytics /> -->
      </div>
      <div class="col-xl-4">
        <SalesAnalytics />
      </div>
    </div>
  </Layout>
</template>
